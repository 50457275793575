import React from "react";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

import { Link } from "react-router-dom";
// @material-ui/icons
import Chat from "@material-ui/icons/Watch";
import VerifiedUser from "@material-ui/icons/VerifiedUser";
import Fingerprint from "@material-ui/icons/Wifi";
// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import InfoArea from "components/InfoArea/InfoArea.jsx";

import productStyle from "assets/jss/material-kit-pro-react/views/landingPageSections/productStyle.jsx";

class SectionProduct extends React.Component {
  render() {
    const { classes } = this.props;
    return (
      <div className={classes.section}>
        <GridContainer justify="center">
          <GridItem xs={12} sm={8} md={8}>
            <h2 className={classes.title}>Features</h2>
            <ul style={{ listStyleType: "none" }}>
              <li className={classes.description}>
              Launch HomeKit and SmartThings actions and scenes <span role="img" aria-labelledby="watch">⌚</span>
              </li>
              <li className={classes.description}>
              Embed and launch your Expo or React Native Apps
              </li>
              <li className={classes.description}>
              Read your news, blogs, and sports feeds <span role="img" aria-labelledby="watch">⌚</span>
              </li>
              <li className={classes.description}>
              Play your podcasts or music  <span role="img" aria-labelledby="watch">⌚</span>
              </li>
              <li className={classes.description}>
              Display a ‘Contacts’ shortcut with call, text, facetime, email, etc <span role="img" aria-labelledby="watch">⌚</span>
              </li>
              <li className={classes.description}>
              Launch built-in iOS apps
              </li>
              <li className={classes.description}>
              Launch apps from the app store
              </li>
            </ul>
          </GridItem>
        </GridContainer>
        <div>
          <GridContainer>
            <GridItem xs={12} sm={4} md={4}>
              <InfoArea
                title="Watch App"
                description="Gidjit has a watch app. All actions that are compatible will be ready for you in the app. Look for the ⌚ icon above"
                icon={Chat}
                iconColor="info"
                vertical
              />
            </GridItem>
            <GridItem xs={12} sm={4} md={4}>
              <InfoArea
                title="Privacy"
                description="Gidjit has no login and collects no data about yourself. The only data sent to our server is the App names and identifiers of Apps we are currently unable to launch"
                icon={VerifiedUser}
                iconColor="success"
                vertical
              />
            </GridItem>
            <GridItem xs={12} sm={4} md={4}>
              <InfoArea
                title="Beacons"
                description="We have released a free hobbyist dev kit for you to program out latest beacon firmware and trigger HomeKit automation. Find in More->Beacons or click link below"
                icon={Fingerprint}
                iconColor="danger"
                vertical
              />
              <Link to={"/beacons"}> New Beacon Kit!</Link>
            </GridItem>
          </GridContainer>
        </div>
      </div>
    );
  }
}

export default withStyles(productStyle)(SectionProduct);
