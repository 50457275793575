import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

import descriptionStyle from "assets/jss/material-kit-pro-react/views/aboutUsSections/descriptionStyle.jsx";

function SectionDescription(props) {
  const { classes } = props;
  return (
    <div className={classNames(classes.aboutDescription, classes.textCenter)}>
      <GridContainer>
        <GridItem
          md={8}
          sm={8}
          className={classNames(classes.mrAuto, classes.mlAuto)}
        >
          <h5 className={classes.description}>
            Our primary purpose for collecting data is to improve your experience with Gidjit and provide superior performance.
            The data that is collected is not shared for any commercial or marketing purpose. It is collected and stored anonymously and securley using industry-standard methods.
          </h5>

          <h5 className={classes.title}>
          What information does Gidjit collect?
          </h5>

          <List className={classes.list}>
            <ListItem className={classes.listItem}>
            Apps names and identifiers of Apps we are currently unable to launch.
            We collect this info so that we can better your experience by having as many apps as possible launchable.
            This information is completely anonymous as we do not require any login information to use the app.
            </ListItem>
          </List>
          <h5 className={classes.title}>
              What information does Gidjit not collect?
          </h5>
          <List className={classes.list}>
            <ListItem className={classes.listItem}>
              We do not require any login information or store any personal information about yourself.
            </ListItem>
            <ListItem className={classes.listItem}>
            We do not collect any data about your home and personal devices and how they are used.
            </ListItem>
            <ListItem className={classes.listItem}>
            We do not collect any type of data that is not mentioned above.
            </ListItem>
          </List>
        </GridItem>
      </GridContainer>
    </div>
  );
}

export default withStyles(descriptionStyle)(SectionDescription);
