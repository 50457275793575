import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// @material-ui/icons
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
//import { Link } from "react-router-dom";
// core components


import sectionTextStyle from "assets/jss/material-kit-pro-react/views/blogPostSections/sectionTextStyle.jsx";

function SectionText({ ...props }) {
  const { classes } = props;
  const imgClasses = classNames(
    classes.imgRaised,
    classes.imgRounded,
    classes.imgFluid
  );
  return (
    <div className={classes.section}>
      <GridContainer justify="center">
        <GridItem xs={12} sm={8} md={8}>
            The following is for those with reasonable techincal ability.
            If you have issues please contact us using the "Contact Developer"
            link in the Settings screen of the Gidjit app.
            If you have already purchased a Gidjit Beacon you
            can use this guide to reprogram them.
            <br />
            <br />
            The first part describes how to turn a Pi 3B/+ into a HomeKit Bridge and Beacon
            programmer. If you are not interested in HomeKit automation and have
            the tools to program hex files you can just skip below to programming beacons.
            You still get all the other features of using beacons with Gidjit.
          <h3 className={classes.title}>
            Creating your Beacon HomeKit Bridge and Programmer with a Pi
          </h3>
          <p>
            The Bridge will make the beacons appear as HomeKit Occupancy detectors
            activated when your iPhone gets close to a beacon. Using Apple's Home
            App you can configure scenes and actions to trigger when activated. For
            example, walk into a room and your lights or fans turn on. Or you go
            to your bedroom at night and have your home lights turn off.
            <br />
            <br />
            The Bridge will allow you to program the latest Gidjit Beacon firmware onto a
             NRF51822 BT module through a web page.
             The web page will show you how to connect the designated SWD pins of of the PI.
          </p>
          <h6 className={classes.title}>
            Caveats and Recommendations
          </h6>
          <ul>
            <li>
              It is preferable that your nrf51822 is powered by a CR2477 or 2-AAA
            </li>
            <li>
              Trigger actions only when occupancy is detected i.e entering a region.
              That's how beacons work best.
            </li>
            <li>
              You will need to be on the same network as the bridge for the
              beacons to trigger home automation. Make sure your bridge is plugged
              in via ethernet cable to your router and properly powered.
            </li>
            <li>
              Region activation may not be fast when your phone is asleep.
              It is best to at least have the phone in your hand to get reliable
               prompt activation.
            </li>
            <li>
             You will need to expermient with some trial and error where best to
             place the beacons, how close to get, and what they should control.
            </li>
            <li>
             Beacons offer a personalized experience that other motion sensors cannot.
             They do not trigger if just anybody enters an area. They are linked with
             just your iPhone/iPad.
             You can add the beacons to other iPhones/iPads. If you would like the
             same automations to occur just use the same name when adding them to
             the Gidjit app.
            </li>
          </ul>
          <h5 className={classes.title}>
            Items needed
          </h5>
          <ul>
            <li>
              Raspberry Pi 3B or 3B+
            </li>
            <li>
              SD card 8GB +
            </li>
            <li>
              Zipped Bridge image to flash on the SD
              &nbsp;&nbsp;<a href="https://s3-us-west-2.amazonaws.com/gidjit-public/beacon/image-pi3-gidjit.img.zip">Download</a>
            </li>
            <li>
              <em>balenaEtcher</em>&nbsp; Application to flash the bridge image
              onto the SD.
              &nbsp;&nbsp;<a href="https://www.balena.io/etcher/">Site</a>
            </li>
            <li>
              USB flash drive
            </li>
            <li>
              Ethernet cable
            </li>
            <li>
              Apple's Home App on your iPhone
            </li>
          </ul>

          <h5 className={classes.title}>
            Steps
          </h5>
          <ol>
            <li>
              Leave Pi completely unpowered
            </li>
            <li>
              Download the Bridge image
            </li>
            <li>
              Unzip the Bridge image and ensure file ends with <strong>.img</strong>
            </li>
            <li>
              Use balenaEtcher to program the image onto your SD
            </li>
            <li>
              Plug the SD into the Pi
            </li>
            <li>
              Plug the USB into the Pi
            </li>
            <li>
              Connect the Pi via ethernet to your router
            </li>
            <li>
              Power the Raspberry Pi with a stable source
            </li>
            <li>
              Wait at least 6 minutes for Pi to install latest firmware and
              begin running application.
            </li>
            <li>
              Remove USB and place in your computer
            </li>
            <li>
              Open file setupURI.png on your computer (QR code).<br /> <strong>If this file
              does not exist or is empty</strong>
                <ol>
                  <li>clear the contents of the USB</li>
                  <li>reinsert USB in Pi</li>
                  <li>cycle power to Pi</li>
                  <li>wait 5 minutes and try again</li>
                </ol>
            </li>
            <li>
              Open Apple's Home app and select add new accessory.
            </li>
            <li>
              Scan the opened setupURI.png
            </li>
            <li>
              If you are having any trouble, open gidjit-bridge-info.txt on your
              usb to add manually with pincode
            </li>
            <li>
              Setup beacons using instructions below
            </li>
            <li>
              Beacons will now appear as Occupancy Sensors.
            </li>
          </ol>
        <p>
        Use Apple's Home App to configure scenes and setup automation to
        trigger when a beacon region or occupancy is detected once your
        have programmed and added beacons to Gidjit app using instructions below.
        </p>
      </GridItem>
      <GridItem xs={12} sm={8} md={8}>
        <h3 className={classes.title}>Programming Beacons with the Bridge</h3>
        <h5 className={classes.title}>
          Steps
        </h5>
        <ol>
          <li>
            Open gidjit-bridge-info.txt placed in your USB from the bridge/programmer to get the ip address or hostname of the device.
            <br />
            ip - inet addr:"###.###.###.###"
            <br />
            hostname - GO-XXXXXXX
          </li>
          <li>
             In your browser enter the url
            <br />
             <strong>http://###.###.###.###/</strong>
            <br />
             <strong>http://GO-XXXXXXX.local/</strong>
          </li>
          <li>
           Use the info and tools provided to program the beacons. When complete
           go the below section <strong>Add Beacons to Gidjit</strong>
          </li>
        </ol>
      </GridItem>
      <GridItem xs={12} sm={8} md={8}>
        <h3 className={classes.title}>Programming Beacons with Hex files</h3>
        <h5 className={classes.title}>
          Items needed
        </h5>
        <ul>
          <li>
            nrf51822 bluetooth device (preferable with CR2477 or 2-AAA)
          </li>
          <li>
            JTAG or SWD programmer for your nrf51822 device
          </li>
          <li>
            Hex file for "Beacon 1"
            &nbsp;&nbsp;<a href="https://s3-us-west-2.amazonaws.com/gidjit-public/beacon/beacon1.hex" download="beacon1.hex">Download</a>
          </li>
          <li>
            Hex file for "Beacon 2"
            &nbsp;&nbsp;<a href="https://s3-us-west-2.amazonaws.com/gidjit-public/beacon/beacon2.hex" download="beacon2.hex">Download</a>
          </li>
          <li>
            Hex file for "Beacon 3"
            &nbsp;&nbsp;<a href="https://s3-us-west-2.amazonaws.com/gidjit-public/beacon/beacon3.hex" download="beacon3.hex">Download</a>
          </li>
        </ul>
        <h5 className={classes.title}>
          Steps
        </h5>
        <ol>
          <li>
            Download "Beacon 1" hex and QR code
          </li>
          <li>
            Use your programmer of choice to flash it to your nrf51822 device
          </li>
          <li>
            Repeat for the remaining beacons then see below for adding the beacon
            to the Gidjit app.
          </li>
        </ol>
      </GridItem>
      <GridItem id="AddGidjitBeacons" xs={12} sm={8} md={8}>
        <h3 className={classes.title}>Add Beacons to Gidjit</h3>
        <ol>
          <li>
            If you are using the Home Bridge make sure it is connected via ethernet
            to your router and properly powered.
          </li>
          <li>
            Open the Gidjit app and go to Settings -> Gidjit Beacons
          </li>
          <li>
            Select + in the top right
          </li>
          <li>
            Enter the beacon name that will best describe it's region
          </li>
          <li>
            Select "Scan QR Code"
          </li>
          <li>
            Scan the corresponding Beacon QR below.
            Becareful to scan the correct code. It is best to click icons below to
            download, open, and scan each QR individually so the camera does not
            get the wrong one.
          </li>
          <li>
            If you are using a Home Bridge open the "Home App", then make sure your
            have a corresponding Occupancy Detector with the same name of the beacon.
          </li>
        </ol>
      </GridItem>
      <GridItem xs={12} sm={10} md={10} className={classes.section}>
        <GridContainer>
          <GridItem xs={12} sm={4} md={4}>
            <h6 >Beacon 1</h6>
            <a href={"https://s3-us-west-2.amazonaws.com/gidjit-public/beacon/qr1.png"} download="qr1.png">
              <img src={"https://s3-us-west-2.amazonaws.com/gidjit-public/beacon/qr1.png"} alt="..." className={imgClasses} />
            </a>
          </GridItem>
          <GridItem xs={12} sm={4} md={4}>
            <h6>Beacon 2</h6>
            <a href={"https://s3-us-west-2.amazonaws.com/gidjit-public/beacon/qr2.png"} download="qr2.png">
              <img src={"https://s3-us-west-2.amazonaws.com/gidjit-public/beacon/qr2.png"} alt="..." className={imgClasses} />
            </a>
          </GridItem>
          <GridItem xs={12} sm={4} md={4}>
            <h6>Beacon 3</h6>
            <a href={"https://s3-us-west-2.amazonaws.com/gidjit-public/beacon/qr3.png"} download="qr3.png">
              <img src={"https://s3-us-west-2.amazonaws.com/gidjit-public/beacon/qr3.png"} alt="..." className={imgClasses} />
            </a>
          </GridItem>
        </GridContainer>
      </GridItem>
    </GridContainer>
  </div>
  );
}

export default withStyles(sectionTextStyle)(SectionText);
