import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
// @material-ui/icons
// core components
import Header from "components/Header/Header.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Parallax from "components/Parallax/Parallax.jsx";
import Footer from "components/Footer/Footer.jsx";
// sections for this page
import HeaderLinks from "components/Header/HeaderLinks.jsx";
import SectionDescription from "views/PrivacyPage/Sections/SectionDescription.jsx";
//import SectionTeam from "views/PrivacyPage/Sections/SectionTeam.jsx";
//import SectionServices from "views/PrivacyPage/Sections/SectionServices.jsx";
//import SectionOffice from "views/PrivacyPage/Sections/SectionOffice.jsx";
//import SectionContact from "views/PrivacyPage/Sections/SectionContact";

import aboutUsStyle from "assets/jss/material-kit-pro-react/views/aboutUsStyle.jsx";

class PrivacyPage extends React.Component {
  componentDidMount() {
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
  }
  render() {
    const { classes } = this.props;
    return (
      <div>
        <Header
          brand="Gidjit - Smart Launcher"
          links={<HeaderLinks dropdownHoverColor="info" />}
          fixed
          color="transparent"
          changeColorOnScroll={{
            height: 300,
            color: "info"
          }}
        />
        <Parallax image={require("assets/images/main-header.jpg")} filter="dark">
          <div className={classes.container}>
            <GridContainer justify="center">
              <GridItem
                md={8}
                sm={8}
                className={classNames(
                  classes.mlAuto,
                  classes.mrAuto,
                  classes.textCenter
                )}
              >
                <h1 className={classes.title}>Privacy</h1>
                <h4>
                 We take your privacy seriously and pledge to be transparent about
                 the information we collect and how it is used. We strive to use
                 the most secure data security tools to keep the data safe.
                 </h4>
              </GridItem>
            </GridContainer>
          </div>
        </Parallax>
        <div className={classNames(classes.main, classes.mainRaised)}>
          <div className={classes.container}>
            <SectionDescription />
          </div>
        </div>
        <Footer
          content={
            <div>
              <div className={classes.left}>
                <List className={classes.list}>
                  <ListItem className={classes.inlineBlock}>
                    <a
                      href="https://itunes.apple.com/us/app/gidjit-smart-launcher/id1179176359?mt=8"
                      className={classes.block}
                    >
                      On the Apple AppStore
                    </a>
                  </ListItem>
                </List>
              </div>
              <div className={classes.right}>
                &copy; {1900 + new Date().getYear()} ,
                Concerco
              </div>
            </div>
          }
        />
      </div>
    );
  }
}

export default withStyles(aboutUsStyle)(PrivacyPage);
