import React from "react";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
// @material-ui/icons
import FormatAlignLeft from "@material-ui/icons/FormatAlignLeft";
// core components
import Header from "components/Header/Header.jsx";
import HeaderLinks from "components/Header/HeaderLinks.jsx";
import Parallax from "components/Parallax/Parallax.jsx";
import Footer from "components/Footer/Footer.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Button from "components/CustomButtons/Button.jsx";
// sections for this page
import SectionText from "./Sections/SectionText.jsx";
//import SectionBlogInfo from "./Sections/SectionBlogInfo.jsx";
//import SectionComments from "./Sections/SectionComments.jsx";
//import SectionSimilarStories from "./Sections/SectionSimilarStories.jsx";

import blogPostPageStyle from "assets/jss/material-kit-pro-react/views/blogPostPageStyle.jsx";

class BlogPostPage extends React.Component {
  componentDidMount() {
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
  }
  render() {
    const { classes } = this.props;
    return (
      <div>
        <Header
          brand="Gidjit - Smart Launcher"
          links={<HeaderLinks dropdownHoverColor="info" />}
          fixed
          color="transparent"
          changeColorOnScroll={{
            height: 300,
            color: "info"
          }}
        />
        <Parallax image={require("assets/images/main-header.jpg")} filter="dark">
          <div className={classes.container}>
            <GridContainer justify="center">
              <GridItem md={8} className={classes.textCenter}>
                <h1 className={classes.title}>
                Try our new beacon & HomeKit dev tools
                </h1>
                <h4 className={classes.subtitle}>
                  We have just released software and instructions to convert a Raspberry Pi 3B/+
                  into a HomeKit bridge enabling you to trigger your HomeKit automation
                  with beacon proximity. The Pi firmware can program our latest beacon firmware in    
                  nrf51822 bluetooth modules 
                </h4>
                <br />
                <Button color="rose" size="lg" round>
                  <FormatAlignLeft /> See Below
                </Button>
              </GridItem>
            </GridContainer>
          </div>
        </Parallax>
        <div className={classes.main}>
          <div className={classes.container}>
            <SectionText />
          </div>
        </div>
        <Footer
          content={
            <div>
              <div className={classes.left}>
                <List className={classes.list}>
                  <ListItem className={classes.inlineBlock}>
                    <a
                      href="https://itunes.apple.com/us/app/gidjit-smart-launcher/id1179176359?mt=8"
                      className={classes.block}
                    >
                      On the Apple App Store
                    </a>
                  </ListItem>
                </List>
              </div>
              <div className={classes.right}>
                &copy; {1900 + new Date().getYear()} ,
                Concerco
              </div>
            </div>
          }
        />
      </div>
    );
  }
}

export default withStyles(blogPostPageStyle)(BlogPostPage);
