import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// react components for routing our app without refresh
import { Link } from "react-router-dom";
// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
// core components
import Button from "components/CustomButtons/Button.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

import sectionsStyle from "assets/jss/material-kit-pro-react/views/landingPageSections/sectionsStyle.jsx";

class SectionSections extends React.Component {
  render() {
    const { classes } = this.props;
    return (
      <div className={classes.section}>
        <GridContainer justify="center">
          <GridItem
            md={8}
            className={classNames(classes.mrAuto, classes.mlAuto)}
          >
            <div className={classes.sectionDescription}>
              <Card>
              <CardBody>
              <h5 className={classes.description}>
                HomeKit beacon automation dev kit is now available! 
              </h5>
              <Link to="/beacons">
                <Button
                  color="info"
                  target="_blank"
                  className={classes.navButton}
                  round
                >
                  See Beacons
                </Button>
              </Link>
              </CardBody>
              </Card>
            </div>
          </GridItem>
        </GridContainer>
      </div>
    );
  }
}

export default withStyles(sectionsStyle)(SectionSections);
